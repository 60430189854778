html,
body {
  padding: 0;
  margin: 0;
  overflow: scroll;
  width: 100vw;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
}

#root {
  height: 100%;
}

a {
  list-style: none;
  text-decoration: none;
  width: 100%;
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0;
}

p {
  line-height: 1.5rem;
  font-size: 0.9rem;
}
